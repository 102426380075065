import React from 'react';
import logo from './logo.svg';
import '../App.css';
import {useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import {Film, FilmDisplay} from './Film';
import API from '../API';

// home page component
export function Home() {
  let [films, setFilms] = useState<Film[]>([])
  useEffect(function(){
    (async function(){
      let api = new API("get","films")
      let data:any = await api.query();
      setFilms(data)
    })()
  }, [])
  return (
    <div id="home-container">
          <div id="home">
        {films.map((film) => (<FilmDisplay key = {film.poster} {...film} />))}
      </div>
    </div>

  );
}

export default Home;
