import {useState,useEffect} from 'react'


interface Props {
    options:string[],
    defaultOption?:string,
    updater:any
}

export default function DropDown(props:Props) {
    const [selected, setSelected] = useState<string>(props.defaultOption?props.defaultOption:"")

    function handleChange(e:any) {
        const {name, value} = e.target;
        props.updater(value)
        setSelected(value)
    }

    return (
        <select value={selected} className = "dropdown" onChange = {(value) => {handleChange(value)}}>
            {props.options.map(opt => (<option key={opt} value={opt}>{opt}</option> ))}
        </select>
    )
}