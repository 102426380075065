
import "./link.css"
import { APIError } from "../Root"
import API from "../API";
import { useContext } from "react";
import { logDOM } from "@testing-library/react";
interface Props {
    color?:string,
    style?:any,
    children:any
}

// default loading animation
export default function Loading(props:Props) {

    // I kind of cheesed this here so that 
    // the api class can interrupt what is displayed
    // and replace it with the loading animation
    // when it is querying data
    
    const {loading, setLoading}:any = useContext(APIError);
    let color = "#ffffff";
    if (props.color) {
        color = props.color
    }
    return (
        <div style = {{width:"100%"}}>
        <div className = "loading" style = {loading?{...props.style}:{...props.style, display:"none"}}>
            <svg version="1.1" id="L7" className = "loading-img" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
        viewBox="0 0 100 100" enableBackground="new 0 0 100 100" >
        <path fill={color} d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
        c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
            <animateTransform 
                attributeName="transform" 
                attributeType="XML" 
                type="rotate"
                dur="2s" 
                from="0 50 50"
                to="360 50 50" 
                repeatCount="indefinite" />
        </path>
        <path fill={color} d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
        c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
            <animateTransform 
                attributeName="transform" 
                attributeType="XML" 
                type="rotate"
                dur="1s" 
                from="0 50 50"
                to="-360 50 50" 
                repeatCount="indefinite" />
        </path>
        <path fill={color} d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
        L82,35.7z">
            <animateTransform 
                attributeName="transform" 
                attributeType="XML" 
                type="rotate"
                dur="2s" 
                from="0 50 50"
                to="360 50 50" 
                repeatCount="indefinite" />
        </path>
        </svg>
        </div>
            <div className = "flex-column" style = {loading?{"width":"100%",display:"none"}:{"width":"100%"}}>
                {props.children}
            </div>
        </div>
    )

}