
import API from "../API"
import ProgressBar from "@ramonak/react-progress-bar";
import "./film.css"

export interface Film {
   name:string,
   review:string,
   color:string,
   poster:string,
   score:number 
}

export function FilmDisplay(props:Film) {
    return (
        <div className="film-container">
            <h2 className = "film-name">{props.name}</h2>
            <img className = "film-img" src = {API.get_image_url(props.poster)} />
            <ProgressBar completed={props.score} customLabelStyles={{fontFamily: `"Courier New", monospace`,color:"#e0e0e0"}}
            bgColor = {props.color}
            className = "review-bar"
            baseBgColor="gray"
            customLabel= {`${props.score}%`}
            maxCompleted={100}/>
            {/* <p className = "film-review">{props.review}</p> */}
        </div>
    )
}