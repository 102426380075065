import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Root from './Root';
import App from './Film/Home';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
  redirect
} from "react-router-dom";
import Background from './Root';
import API  from './API';
import Error404 from "./Static/404"
import Photos from "./Photos/Route"
// simple redirect function for
// routes that redirect to another website
function Redirect(props:any):any {
  API.setLoading(true);
  window.location.href = props.route
  return (<div></div>)
};

/* existing imports */

// sets up routing for the various webpages
// see documentation for react router v6
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement:<Error404 />,
    children: [
      {
        path: "",
        element: <App />,
      },
      {
        path:"photos",
        element: <Photos />
      },
      {
        path: "*",
        element: <Error404 />,
      }
    ],
  },
]);

//initializes react webpage
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
