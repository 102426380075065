import {City,Photo,PhotoDisplay} from "./Photo"
import {useState, useEffect} from "react"
import DropDown from "./DropDown"
import API from "../API"
import "./photo.css"

export default function Photos() {
    const [images, setImages] = useState<Photo[]>([])
    const [cities, setCities] = useState<City[]>([])

    async function updateImages(city:string) {
        if (city) {
            let api = new API("get","photos")
            let data:any = await api.query({"city":city})
            setImages(data)
            console.log(data)
        }
    }

    useEffect(function(){
        (async function() {
            let api = new API("get","cities")
            let data:any = await api.query()
            setCities(data)
            if (data.length != 0) {
                updateImages(data[0].name)
            }
        })()
    }, [])



    return (
    <div id="home-container">
        <div id="home" style = {{"width":"100%"}}>
             <DropDown options = {cities.map(city => (city.name))} updater = {updateImages} />
            {images.map(photo => (<PhotoDisplay key={photo.image} {...photo} />))}
        </div>
    </div>
    )
}