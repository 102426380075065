
import API from "../API"


export interface City {
    name:string,
    description:string
}
export interface Photo {
    location:City,
    description:string,
    image:string

}

export function PhotoDisplay(props:Photo) {
    return (
        <div className="film-container photo-container">
            <img className = "photo-img" src = {API.get_image_url(props.image)} />
            <p className = "film-review">{props.description}</p>
        </div>
    )
}